import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';

import Navbar from '../components/Navbar';
import PageHeader from '../components/PageHeader';
import ColumnDescriptions from '../components/ColumnDescriptions';
import '../styles/index.scss';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import List from "../components/List";
import Card from "../components/Card";
import JesusPhoto from '../images/photos/mobile/jesus.png';
import LuisPhoto from '../images/photos/mobile/luis.png';
import EdwinPhoto from '../images/photos/mobile/edwin.png';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import InterestSection from "../components/InterestSection";
import getMetadata from '../utils/getMetadata';
import FloatButton from "../components/FloatButton";
import mailWhiteIcon from '../images/icons/mailWhite.svg'; 
import wppWhiteIcon from '../images/icons/wppWhite.svg'; 

const AboutUs = ({ pageContext, location }) => {
  const { lang } = pageContext;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [])

  return (
    <>
      {
        getMetadata({
          pageName: 'aboutUs',
          link: 'about',
          lang,
          t,
        })
      }
      <Navbar location={location}/>
      <FloatButton
        image={mailWhiteIcon}
        imageAlt={t('floatButton.contact.imageAlt')}
        link={`/${lang}/contact`}
        index={0}
      />
      <FloatButton
        image={wppWhiteIcon}
        imageAlt={t('floatButton.wpp.imageAlt')}
        link={`https://wa.me/51998785550?text=${encodeURIComponent(t('floatButton.wpp.text'))}`}
        index={1}
        isExternalLink={true}
      />
      <PageHeader
        title={t(`aboutUs.header.title`)}
        description={t(`aboutUs.header.description`)}
        photo='about'
        photoAlt={t('aboutUs.header.imageAlt')}
      />
      <Section
        title={t('aboutUs.sections.team.title')}
        isGray={true}
        items={[
          (
            <SectionTitle
              title={t('aboutUs.sections.team.subTitle')}
            />
          ),
          (
            <List
              items={[
                (
                  <Card
                    title={t('aboutUs.sections.team.jesus.title')}
                    description={t('aboutUs.sections.team.jesus.description')}
                    type='personCard'
                    email={t('aboutUs.sections.team.jesus.email')}
                    linkedin={t('aboutUs.sections.team.jesus.linkedin')}
                    image={JesusPhoto}
                    imageAlt={t('aboutUs.sections.team.jesus.title')}
                  />
                ),
                (
                  <Card
                    title={t('aboutUs.sections.team.luis.title')}
                    description={t('aboutUs.sections.team.luis.description')}
                    type='personCard'
                    email={t('aboutUs.sections.team.luis.email')}
                    linkedin={t('aboutUs.sections.team.luis.linkedin')}
                    image={LuisPhoto}
                    imageAlt={t('aboutUs.sections.team.luis.title')}
                  />
                ),
                (
                  <Card
                    title={t('aboutUs.sections.team.edwin.title')}
                    description={t('aboutUs.sections.team.edwin.description')}
                    type='personCard'
                    email={t('aboutUs.sections.team.edwin.email')}
                    linkedin={t('aboutUs.sections.team.edwin.linkedin')}
                    image={EdwinPhoto}
                    imageAlt={t('aboutUs.sections.team.edwin.title')}
                  />
                ),
              ]}
            />
          ),
        ]}
      />
      <Section
        title={t('aboutUs.sections.values.title')}
        isTwoColumns={true}
        items={[
          (
            <SectionTitle
              title={t('aboutUs.sections.values.subTitle')}
            />
          ),
          (
            <ColumnDescriptions
              items={[
                {
                  title: t('aboutUs.sections.values.value1.title'),
                  description: t('aboutUs.sections.values.value1.description'),
                },
                {
                  title: t('aboutUs.sections.values.value2.title'),
                  description: t('aboutUs.sections.values.value2.description'),
                },
                {
                  title: t('aboutUs.sections.values.value3.title'),
                  description: t('aboutUs.sections.values.value3.description'),
                },
              ]}
            />
          )
        ]}
      />
      <ContactSection
        mobileMainTitle={t('home.sections.contact.title')}
        title={t('home.sections.contact.subTitle')}
        description={t('home.sections.contact.description')}
        isGray={true}
      />
      <InterestSection
        name='services'
        link={`/${lang}/services`}
      />
      <Footer lang={lang}/>
    </>
  );
};

export default AboutUs;
